'use client'

import { Box, BoxProps as MuiBoxProps, styled } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'

interface SectionProps extends MuiBoxProps {
	bgColor?: boolean | string
	darkness?: number
	src?: {
		bgMoImgUrl: string
		bgTbImgUrl: string
		bgPcImgUrl: string
	}
}

const BgWrapper = styled(Box)<SectionProps>(({ theme, src, darkness = 0.45 }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: zIndexes.under,
	width: '100%',
	height: '100%',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundImage: `url(${src?.bgMoImgUrl})`,
	[theme.breakpoints.up('md')]: {
		backgroundImage: `url(${src?.bgTbImgUrl})`,
	},
	[theme.breakpoints.up('lg')]: {
		backgroundImage: `url(${src?.bgPcImgUrl})`,
	},
	'&::before': {
		content: '""',
		display: 'block',
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: `rgba(0, 0, 0, ${darkness})`,
	},
	'&::after': {
		content: '""',
		display: 'block',
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		height: '60%',
		backdropFilter: 'blur(20px)',
		background: `linear-gradient(to top, rgba(0, 0, 0, ${darkness}) 50%, transparent 100%)`,
		maskImage: 'linear-gradient(to top, #000 30%, transparent 100%)',
	},
}))

const getBgColorValue = (bgColor: boolean | string | undefined): string => {
	if (typeof bgColor === 'string') {
		return bgColor
	}
	if (bgColor === true) {
		return color.colGray1
	}
	return 'transparent'
}

const Section = ({ children, bgColor, darkness, src, sx, ...props }: SectionProps) => {
	const bgColorValue = getBgColorValue(bgColor)
	const paddingValue = {
		xs: `${pxToRem(12)} 0 ${pxToRem(42)}`,
		md: `${pxToRem(16)} 0 ${pxToRem(48)}`,
	}
	return (
		<Box
			padding={bgColor || src ? paddingValue : undefined}
			position="relative"
			sx={{
				background: bgColorValue,
				...sx,
			}}
			{...props}
			className={`${props.className ? props.className : ''} ${bgColor ? 'set-color' : ''}`}
		>
			{/* 배경 이미지가 있는 경우 */}
			{src && <BgWrapper src={src} darkness={darkness} />}
			{children}
		</Box>
	)
}

export default Section
