'use client'

import { forwardRef } from 'react'
import { DividerProps as MuiDividerProps } from '@mui/joy'
import UIDividerRoot from './UIDividerRoot'

type OrientType = 'vertical' | 'horizontal'
export interface UIDividerProps extends Omit<MuiDividerProps, 'orientation'> {
	isDark?: boolean
	orientation?:
		| OrientType
		| {
				xs?: OrientType
				sm?: OrientType
				md?: OrientType
				lg?: OrientType
				xl?: OrientType
		  }
}

/**
 * UIDivider 컴포넌트
 * MUI Grid 컴포넌트 내에서 사용하기 위하여 수정된 컴포넌트입니다.
 * margin 처리를 통하여 Grid 내에서 자리를 차지하지 않습니다.
 * @param [props.orientation] - 기존 orientation과 동일하지만 {{xs:'vertical', md:'horizontal'}} 와 같이 반응형 형태로 입력이 가능합니다.
 * @param [props.isDark] - 다크모드 시 rgba(255,255,255,0.2) 색상으로 변경됩니다
 */
const UIDivider = forwardRef<HTMLHRElement, UIDividerProps>(
	({ className, orientation = 'vertical', isDark, sx, ...props }, ref) => {
		return <UIDividerRoot ref={ref} sx={sx} ownerState={{ orientation, isDark }} {...props} />
	},
)

export default UIDivider
