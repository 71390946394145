import { MouseEvent, useEffect, useState } from 'react'
import { ApiResultVoBoolean } from '@schemas/auth'
import useModal from '@components/layout/Modal'
import UIButton from '@components/ui/UIButton'
import { useLiveReservationMutation } from '@services/api/Live/Reservation/mutation'
import { useAuthControl } from '@store/auth'
import { useNotification } from '@lib/notification'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { useIsAppControl } from '@store/isApp'
import { useOpenHeaderState } from '@components/layout/Header/store/store'

interface LiveReserveButtonProps {
	idx: number
	title?: string
	isReserved?: boolean
}
const LiveReserveButton = ({ idx, title, isReserved = false }: LiveReserveButtonProps) => {
	const { auth } = useAuthControl()
	const { isApp } = useIsAppControl()
	const confirm = useModal()
	const { notify } = useNotification()
	const handleSettingOpen = useOpenHeaderState('settingOpen')
	const [streamingAlarmYn] = useLocalStorage<boolean>('streaming_alarm_yn')
	const [reserve, setReserve] = useState<boolean>()
	const { mutateAsync: liveReserveMutate } = useLiveReservationMutation()

	const toggleReservation = async () => {
		const result: ApiResultVoBoolean = await liveReserveMutate({ liveScheduleIdx: idx })
		if (result?.data) {
			setReserve(!reserve)
			if (!reserve) {
				notify(
					`${
						title ? `${title} ` : ''
					}시청 예약이 완료되었습니다.\n시작 시 알림이 발송됩니다.`,
				)
			}
		}
	}
	const handleClick = async (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (!auth) {
			confirm.login()
		} else if (!reserve) {
			if (isApp && !streamingAlarmYn) {
				confirm.open({
					title: '시청 예약 알림 설정 안내',
					message: `시청 예약 알림 기능이 꺼져 있습니다.\n설정에서 방송 시작 알림 수신을 해주세요.`,
					buttons: [
						{ text: '취소' },
						{
							text: '설정',
							onClick: (close) => {
								handleSettingOpen()
								close()
							},
						},
					],
				})
			} else {
				toggleReservation()
			}
		} else {
			confirm.open({
				message: `${title ? `${title} ` : ''}시청 예약을 취소하시겠습니까?`,
				buttons: [
					{ text: '취소' },
					{
						text: '확인',
						onClick: (close) => {
							close()
							toggleReservation()
						},
					},
				],
			})
		}
	}
	useEffect(() => {
		setReserve(isReserved)
	}, [isReserved])
	return reserve ?? isReserved ? (
		<UIButton color="light" onClick={handleClick} sx={{ flexShrink: 0 }}>
			예약취소
		</UIButton>
	) : (
		<UIButton onClick={handleClick} sx={{ flexShrink: 0 }}>
			시청예약
		</UIButton>
	)
}
export default LiveReserveButton
