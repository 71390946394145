export const getLocalStorageItem = (key: string) => {
	return window.localStorage.getItem(key)
}

export const getLocalStorageServerSnapshot = () => {
	// throw Error('useLocalStorage is a client-only hook')
	return ''
}

type StringOrNil = string | null | undefined

function dispatchStorageEvent(key: StringOrNil, newValue: StringOrNil) {
	window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

export const removeLocalStorageItem = (key: string) => {
	window.localStorage.removeItem(key)
	dispatchStorageEvent(key, null)
}

export const setLocalStorageItem = (key: string, value: unknown) => {
	const stringifiedValue = JSON.stringify(value)
	window.localStorage.setItem(key, stringifiedValue)
	dispatchStorageEvent(key, stringifiedValue)
}
