'use client'

import { Divider } from '@mui/joy'
import { styled, useTheme } from '@mui/joy/styles'
import { UIDividerProps } from '.'

interface OwnerState {
	orientation?: UIDividerProps['orientation']
	isDark?: boolean
}

const UIDividerRoot = styled(Divider)(({ ownerState }: { ownerState: OwnerState }) => {
	const { orientation, isDark } = ownerState
	const { color, breakpoints } = useTheme()

	const orientStyle = () => {
		const verticalStyle = {
			inlineSize: 'var(--Divider-thickness)',
			blockSize: 'initial',
			marginLeft: '-1px',
		}
		const horizontalStyle = {
			inlineSize: 'initial',
			blockSize: 'var(--Divider-thickness)',
			marginTop: '-1px',
			width: '100%',
		}

		if (typeof orientation === 'string') {
			if (orientation === 'vertical') {
				return verticalStyle
			}
			if (orientation === 'horizontal') {
				return horizontalStyle
			}
			return null
		}

		const styles: Record<string, typeof verticalStyle | typeof horizontalStyle | undefined> = {}

		if (orientation?.xs === 'vertical') {
			styles[breakpoints.up('xs')] = verticalStyle
		} else if (orientation?.xs === 'horizontal') {
			styles[breakpoints.up('xs')] = horizontalStyle
		}

		if (orientation?.sm === 'vertical') {
			styles[breakpoints.up('sm')] = verticalStyle
		} else if (orientation?.sm === 'horizontal') {
			styles[breakpoints.up('sm')] = horizontalStyle
		}

		if (orientation?.md === 'vertical') {
			styles[breakpoints.up('md')] = verticalStyle
		} else if (orientation?.md === 'horizontal') {
			styles[breakpoints.up('md')] = horizontalStyle
		}

		if (orientation?.lg === 'vertical') {
			styles[breakpoints.up('lg')] = verticalStyle
		} else if (orientation?.lg === 'horizontal') {
			styles[breakpoints.up('lg')] = horizontalStyle
		}

		if (orientation?.xl === 'vertical') {
			styles[breakpoints.up('xl')] = verticalStyle
		} else if (orientation?.xl === 'horizontal') {
			styles[breakpoints.up('xl')] = horizontalStyle
		}

		return styles
	}
	const styles = {
		background: isDark ? 'rgba(255,255,255, 0.2)' : color.colGray3,
		// subpixel 렌더링 방지
		transform: 'perspective(0)',
		...orientStyle(),
	}
	return styles
})

export default UIDividerRoot
