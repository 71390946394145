'use client'

import styled from '@emotion/styled'
import { theme } from '@ui/style'
import { pxToRem } from '@ui/style/muiTheme'
import { IconArrowSlide, IconArrowSlideProps } from 'src/assets/icons/IconArrowSlide'

interface NavButtonProps extends IconArrowSlideProps {
	navPositionTop?: string
	hasPagination?: boolean
	className?: string
	isDark?: boolean
	onClick?: () => void
}

const NavButtonRoot = styled('button')(
	({
		direction,
		disabled,
		stroked,
		size = 'lg',
		navPositionTop,
		hasPagination,
	}: NavButtonProps) => {
		const sizeValue = {
			lg: 48,
			md: 36,
		}
		const topValue = navPositionTop
			? `calc(${navPositionTop} - ${pxToRem(sizeValue[size] / 2)})`
			: `calc(((100% - ${hasPagination ? pxToRem(24) : '0rem'}) / 2) - ${pxToRem(
					sizeValue[size] / 2,
			  )})`

		const positionStyle = () => {
			return {
				left: direction === 'right' ? 'auto' : 0,
				right: direction === 'left' ? 'auto' : 0,
				top: topValue,
				[theme.breakpoints.up('lg')]: {
					left: direction === 'right' ? 'auto' : pxToRem(-sizeValue[size] / 2),
					right: direction === 'left' ? 'auto' : pxToRem(-sizeValue[size] / 2),
				},
				'& svg': {
					marginLeft: direction === 'right' ? pxToRem(1.5) : 0,
					marginRight: direction === 'left' ? pxToRem(1.5) : 0,
				},
			}
		}
		const styles = {
			display: !stroked && disabled ? 'none' : 'block',
			...positionStyle(),
			width: 'auto',
			height: 'auto',
			margin: 0,
			'&::after': {
				display: 'none',
			},
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		}
		return styles
	},
)
const ariaLabels = {
	left: '이전 슬라이드',
	right: '다음 슬라이드',
}

const NavButton = ({
	direction = 'left',
	disabled,
	navPositionTop,
	hasPagination,
	size,
	stroked,
	className,
	isDark,
	onClick,
}: NavButtonProps) => {
	return (
		<NavButtonRoot
			size={size}
			direction={direction}
			disabled={disabled}
			stroked={stroked}
			navPositionTop={navPositionTop}
			hasPagination={hasPagination}
			className={className}
			isDark={isDark}
			onClick={onClick}
			aria-label={ariaLabels[direction] || ''}
		>
			<IconArrowSlide
				direction={direction}
				size={size}
				stroked={stroked}
				disabled={disabled}
				isDark={isDark}
			/>
		</NavButtonRoot>
	)
}

export default NavButton
