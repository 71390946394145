'use client'

import UIFlex from '@components/ui/UIFlex'
import color from '@ui/style/color.theme'

export interface IconArrowSlideProps {
	direction?: 'right' | 'left'
	size?: 'md' | 'lg'
	stroked?: boolean
	disabled?: boolean
	isDark?: boolean
}
const sizeValue = {
	lg: 48,
	md: 36,
}
const svgSizeValue = {
	lg: {
		width: 13,
		height: 22,
	},
	md: {
		width: 8,
		height: 14,
	},
}
const pathConfig = {
	lg: {
		left: 'M12 1.5L2.5 11L12 20.5',
		right: 'M1 20.5L10.5 11L1 1.5',
	},
	md: {
		left: 'M8 1L2 7L8 13',
		right: 'M1 13L7 7L1 1',
	},
}
export const IconArrowSlide = ({
	direction = 'left',
	size = 'lg',
	stroked,
	disabled,
	isDark,
}: IconArrowSlideProps) => {
	return (
		<UIFlex
			alignItems="center"
			justifyContent="center"
			width={sizeValue[size]}
			height={sizeValue[size]}
			borderRadius="9999px"
			border={stroked ? `1px solid ${color.colGray3}` : 0}
			sx={{
				background: stroked
					? color.colWhite
					: isDark
					? 'rgba(86,86,86,0.6)'
					: 'rgba(255,255,255,0.6)',
				filter: stroked ? 'none' : 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))',
				'&:hover': {
					background: isDark ? 'rgba(86,86,86,0.6)' : 'rgba(255,255,255,1)',
					borderColor: disabled ? '0' : color.colBlack,
				},
			}}
		>
			<svg
				width={svgSizeValue[size].width}
				height={svgSizeValue[size].height}
				viewBox={`0 0 ${svgSizeValue[size].width} ${svgSizeValue[size].height}`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					width: 'initial',
					height: 'initial',
				}}
			>
				<path
					d={pathConfig[size][direction]}
					stroke={disabled ? color.colGray4 : isDark ? color.colGray1 : color.colBlack}
					strokeWidth={size === 'lg' ? 2.5 : 2}
				/>
			</svg>
		</UIFlex>
	)
}
