'use client'

import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import Box from '@mui/joy/Box'
import { FrontUrlLinkResponseVo } from '@schemas/non-auth'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { IconGoIcons } from 'src/assets/icons/IconGoIcons'

interface SectionTitleProps extends Omit<UIFlexProps, 'ref'> {
	showLine?: boolean
	showGoButton?: boolean
	isDark?: boolean
	hLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
	href?: string
	link?: FrontUrlLinkResponseVo
	textSize?: 'md' | 'lg'
}
const SectionTitle = ({
	children,
	showLine,
	showGoButton,
	isDark,
	hLevel = 'h2',
	href,
	link,
	sx,
	textSize = 'lg',
}: SectionTitleProps) => {
	const fontSizeSet = {
		lg: {
			xs: pxToRem(24),
			md: pxToRem(26),
			lg: pxToRem(26),
			xl: pxToRem(28),
		},
		md: {
			xs: pxToRem(22),
			md: pxToRem(24),
			lg: pxToRem(24),
			xl: pxToRem(26),
		},
	}
	return (
		<UIFlex
			className="sectionTitle"
			flexDirection="row"
			borderTop={showLine ? `4px solid ${color.colGray2}` : 0}
			paddingTop={{
				xs: pxToRem(16 - (showLine ? 4 : 0)),
				md: pxToRem(22 - (showLine ? 4 : 0)),
				xl: pxToRem(24 - (showLine ? 4 : 0)),
			}}
			paddingBottom={{ xs: pxToRem(16), md: pxToRem(22), xl: pxToRem(24) }}
			sx={{
				...sx,
				'.goIcon-xl': {
					display: {
						xs: 'none',
						xl: 'block',
					},
				},
				'.goIcon-lg': {
					display: {
						xl: 'none',
					},
				},
			}}
		>
			{/* title */}
			{href || link ? (
				<UILink href={href} link={link}>
					<UIText
						component={hLevel}
						fontSize={{ ...fontSizeSet[textSize] }}
						lineHeight={pxToRem(36)}
						fontWeight={700}
						lineClamp={1}
						color={isDark ? color.colWhite : color.colBlack}
						className="sectionTitle-title"
					>
						{children}
					</UIText>
				</UILink>
			) : (
				<UIText
					component={hLevel}
					fontSize={{ ...fontSizeSet[textSize] }}
					lineHeight={pxToRem(36)}
					fontWeight={700}
					color={isDark ? color.colWhite : color.colBlack}
					className="sectionTitle-title"
				>
					{children}
				</UIText>
			)}

			{/* go button without link */}
			{showGoButton && !(href || link) && (
				<Box ml="auto">
					<UIText readonly>더 보기</UIText>
					<IconGoIcons size="lg" isDark={isDark} className="goIcon-lg" />
					<IconGoIcons size="xl" isDark={isDark} className="goIcon-xl" />
				</Box>
			)}
			{/* go button with link */}
			{showGoButton && (href || link) && (
				<Box {...{ component: UILink, href, link }} ml="auto">
					<UIText readonly>더 보기</UIText>
					<IconGoIcons size="lg" isDark={isDark} className="goIcon-lg" />
					<IconGoIcons size="xl" isDark={isDark} className="goIcon-xl" />
				</Box>
			)}
		</UIFlex>
	)
}

export default SectionTitle
