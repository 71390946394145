import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { ApiResultVoBoolean } from '@schemas/auth'
import { fetchAPI } from '@utils/http/apiRequest'

export type LiveReservationResponse = CommonResponse<ApiResultVoBoolean>
export type LiveReservationReqModel = {
	liveScheduleIdx?: number
}

const path = '/restapi/v1/post/contents/live/reservation'
const queryKeyString = (paramsKey?: LiveReservationReqModel) =>
	['@contents/live/reservation', paramsKey] as const

const getQuery = async (
	props: LiveReservationReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoBoolean> => {
	const { liveScheduleIdx } = props
	const params = new URLSearchParams({
		...(liveScheduleIdx ? { liveScheduleIdx: String(liveScheduleIdx) } : {}),
	})
	return fetchAPI({ path, params, method: 'POST', cookieHeader })
}

export const queryLiveReservation = (props: LiveReservationReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString(props)],
	queryFn: () => getQuery(props, cookieHeader),
})

export const liveReservationMutationProps = (cookieHeader?: string) => ({
	mutationKey: queryKeyString(),
	mutationFn: (props: LiveReservationReqModel) => getQuery(props, cookieHeader),
})

export const prefetchLiveReservation = (props: LiveReservationReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props, cookieHeader))
