import { ApiResultVoBoolean } from '@schemas/non-auth'
import { useMutation } from '@tanstack/react-query'
import { LiveReservationReqModel, liveReservationMutationProps } from '.'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useLiveReservationMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<ApiResultVoBoolean, Error, LiveReservationReqModel>(
		liveReservationMutationProps(cookieHeader),
	)
	return { data: data?.data, ...rest }
}
