import { ArticleItemProps } from '@components/list'
import { VodInfo } from '@schemas/non-auth'

export const getLinkType = (item: ArticleItemProps) => {
	if (item?.code === 'LIVE') {
		return 'live'
	}
	if (!item?.code || item?.code === 'ARTICLE') {
		return !item?.isVideoView
			? 'article'
			: item?.vodInfo?.videoType?.code === 'SHORT_FORM'
			? 'shorts'
			: 'video'
	}
	return undefined
}

export const getParams = (searchParams: { [key: string]: string | number | undefined }) => {
	const params = new URLSearchParams()
	const computeSearchParam = (key: string) => {
		if (searchParams[key]) {
			params.append(key, String(searchParams[key]))
		}
		return key
	}
	Object.keys(searchParams).map(computeSearchParam)
	return params.toString()
}

export const getAreaParams = (areaDiv?: string, areaIdx?: number | string) => {
	const params = getParams({
		areaDiv,
		areaIdx,
	})
	return params.toString()
}

export const getArticleParams = (areaDiv?: string, areaIdx?: number | string) => {
	const params = getParams({
		influxDiv: 'JTBC',
		areaDiv,
		areaIdx,
	})
	return params.toString()
}

export const getArticleLink = (
	item: ArticleItemProps,
	areaDiv?: string,
	areaIdx?: number | string,
) => {
	const linkType = getLinkType(item)
	if (linkType) {
		const param =
			!item?.code || item?.code === 'ARTICLE'
				? getArticleParams(areaDiv, areaIdx)
				: getAreaParams(areaDiv, areaIdx)
		return item?.articleIdx ? `/${linkType}/${item?.articleIdx}?${param}` : '#'
	}
	return undefined
}

export const getAccessListParams = (code?: string, idx?: string) => {
	const params = getParams({
		influxDiv: 'JTBC',
		code,
		idx,
	})
	return params.toString()
}

export const getAccessListLink = (item: ArticleItemProps, code?: string, idx?: string) => {
	const linkType = getLinkType(item)
	if (linkType) {
		const param = getAccessListParams(code, idx)
		return `/${linkType}/${item.articleIdx}?${param}`
	}
	return undefined
}

export const getArticleLinkById = (idx: string, isVideo?: boolean) => {
	const path = isVideo ? 'video' : 'article'
	return `/${path}/${idx}?influxDiv=JTBC`
}

export const getPackageLinkType = (code: string) => {
	if (['CORNER', 'SERIES'].includes(code)) {
		return 'series'
	}
	if (code === 'ISSUE') {
		return 'issues'
	}
	if (code === 'TOPIC') {
		return 'topic'
	}
	return ''
}

export const getVideoOrArticleLink = (
	articleIdx: string,
	isVideoView: boolean,
	vodInfo: VodInfo,
) => {
	const param = 'influxDiv=JTBC'
	const baseLink = `/article/${articleIdx}?${param}`

	if (!isVideoView || !vodInfo) {
		return baseLink
	}

	const { videoIdx, videoType } = vodInfo

	if (!videoIdx || !videoType) {
		return baseLink
	}

	switch (videoType.code) {
		case 'SHORT_FORM':
			return `/shorts/${articleIdx}?${param}`
		default:
			return `/video/${articleIdx}?${param}`
	}
}
