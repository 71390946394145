'use client'

import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import { SxProps } from '@mui/joy/styles/types'
import { pxToRem } from '@ui/style/muiTheme'

interface SectionContainerProps extends Omit<UIFlexProps, 'ref'> {}
const SectionContainer = ({ children, sx }: SectionContainerProps) => {
	return (
		<UIFlex
			sx={(theme) => {
				const resolvedSx = typeof sx === 'function' ? sx(theme) : sx
				const additionalStyles: SxProps = {
					'&> *:not(:first-child)': {
						marginTop: { xs: pxToRem(48), md: pxToRem(64), xl: pxToRem(84) },
					},
				}
				return { ...resolvedSx, ...additionalStyles }
			}}
		>
			{children}
		</UIFlex>
	)
}

export default SectionContainer
