'use client'

import React, { useRef, useState } from 'react'
import { Navigation, Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType, SwiperOptions } from 'swiper/types'
import { theme } from '@ui/style'
import { Box, BoxProps as MuiBoxProps } from '@mui/joy'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { IconArrowSlideProps } from 'src/assets/icons/IconArrowSlide'
import NavButton from './NavButton'
import pxToRem from '@utils/pxToRem'

interface CarouselProps extends SwiperOptions {
	children: React.ReactNode
	pagination?: boolean
	navigation?: boolean
	navPositionTop?: string
	navSize?: IconArrowSlideProps['size']
	navStroked?: IconArrowSlideProps['stroked']
	isDark?: boolean
	sx?: MuiBoxProps['sx']
}
export default function Carousel({
	children,
	pagination = false,
	navigation = true,
	navPositionTop,
	navSize,
	navStroked,
	isDark,
	sx,
	...swiperOptions
}: CarouselProps) {
	const swiperSlide = useRef<SwiperType>()
	const [swiperInfo, setSwiperInfo] = useState<SwiperType>()
	const childList = React.Children.toArray(children)

	return (
		<Box
			position="relative"
			sx={{
				'.swiper-pagination-bullets.swiper-pagination-horizontal': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					bottom: 0,
					top: 'auto',
					left: 0,
					width: '100%',
					flexWrap: 'wrap',
					marginLeft: pxToRem(-4),
					marginRight: pxToRem(-4),
					marginBottom: pxToRem(-4),
				},
				'.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet': {
					marginLeft: pxToRem(4),
					marginRight: pxToRem(4),
					marginBottom: pxToRem(4),
				},
				'.swiper-pagination-bullet': {
					opacity: 1,
					background: theme.color.colGray3,
					width: pxToRem(8),
					height: pxToRem(8),
				},
				'.swiper-pagination-bullet-active': {
					background: theme.color.colDarkBlue,
				},
				'.swiper-pagination': {
					position: 'static',
					marginTop: pxToRem(16),
				},
				'.swiper-slide': {
					height: 'initial',
				},
				...sx,
			}}
		>
			<Swiper
				modules={[Navigation, Pagination, A11y]}
				pagination={pagination ? { clickable: true } : false}
				onSwiper={(swiper: SwiperType) => {
					setSwiperInfo({ ...swiper })
					swiperSlide.current = swiper
				}}
				onSlideChange={(swiper: SwiperType) => {
					setSwiperInfo({ ...swiper })
				}}
				onResize={(swiper: SwiperType) => {
					setSwiperInfo({ ...swiper })
				}}
				onTransitionEnd={(swiper: SwiperType) => {
					setSwiperInfo({ ...swiper })
				}}
				{...swiperOptions}
			>
				{childList.map((child, index) => {
					// eslint-disable-next-line react/no-array-index-key
					return <SwiperSlide key={index}>{child}</SwiperSlide>
				})}
			</Swiper>
			{navigation &&
				swiperInfo &&
				childList.length > 0 &&
				swiperInfo.width < swiperInfo?.virtualSize && (
					<Box>
						{swiperInfo.isBeginning}
						<NavButton
							direction="left"
							disabled={!!swiperInfo.isBeginning}
							size={navSize}
							stroked={navStroked}
							navPositionTop={navPositionTop}
							hasPagination={pagination}
							isDark={isDark}
							className={`${isDark ? 'darkmode' : ''} swiper-button-prev`}
							onClick={() => {
								swiperSlide.current?.slidePrev()
							}}
						/>
						<NavButton
							direction="right"
							disabled={!!swiperInfo.isEnd}
							size={navSize}
							stroked={navStroked}
							navPositionTop={navPositionTop}
							hasPagination={pagination}
							isDark={isDark}
							className="swiper-button-next"
							onClick={() => {
								swiperSlide.current?.slideNext()
							}}
						/>
					</Box>
				)}
		</Box>
	)
}
